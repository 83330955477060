import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`L-Hold 4x:20 & Death March 4×5/leg`}</p>
    <p>{`then,`}</p>
    <p>{`Snatch Grip Deadlifts 4×6\\@70% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`15:00 AMRAP of:`}</p>
    <p>{`5-Deadlifts (275/185)`}</p>
    <p>{`15-V Ups`}</p>
    <p>{`.25-Mile Run`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      